import { createModel } from '@rematch/core';
import { _GetProfileCars } from '@Services/Car';
import { ProfileCar } from 'types/Car';
import { RootModel } from '.';

type ProfileCarsState = {
  data: null | ProfileCar[];
  isLoading: boolean;
};

const ProfileCars = createModel<RootModel>()({
  name: 'profileCars',
  state: {
    data: null,
    isLoading: true,
  } as ProfileCarsState,
  reducers: {
    setProfileCars(state, payload: ProfileCar[]) {
      state.data = payload;
    },
    setLoading(state, payload: boolean) {
      state.isLoading = payload;
    },
  },
  effects: (dispatch) => ({
    async getProfileCars() {
      const data = await _GetProfileCars();
      dispatch.profileCars.setProfileCars(data.results);
      dispatch.profileCars.setLoading(false);
    },
  }),
});

export default ProfileCars;
