import Axios from 'lib/Axios';
import { APIResponse } from 'types/APIResponse';
import {
  CheckoutData,
  CheckoutToOrderData,
  PaymentMethods,
  PickupCity,
  PickupItemData,
  PickupItemParams,
  PickupPoint,
} from 'types/Checkout';
import { DefaultPagination } from 'types/common';

export const _GetCheckout = () => Axios.get<CheckoutData>('/shopcart/to-checkout').then((res) => res.data);

export const _GetPaymentMethods = () =>
  Axios.get<APIResponse<PaymentMethods>>('/lookups/list-payment-method').then((res) => res.data);

export const _ApplyAddress = (address_id: number) =>
  Axios.get<CheckoutData>(`/checkout/apply-address/${address_id}`).then((res) => res.data);

export const _ApplyPickupPoint = (pick_up_id: number) =>
  Axios.get<CheckoutData>(`/checkout/apply-pick-up-points/${pick_up_id}`).then((res) => res.data);

export const _CreatePickupItem = (data: PickupItemParams) =>
  Axios.post<PickupItemData>(`/profile/create-pick-up-points`, data).then((res) => res.data);

export const _ApplyPaymentMethod = (payment_method: string) =>
  Axios.get<CheckoutData>(`/checkout/apply-payment-method/${payment_method}`).then((res) => res.data);

export const _ApplyPromoCode = (code: string) =>
  Axios.get<CheckoutData>(`/checkout/apply-promo-code/${code}`)
    .then((res) => res.data)
    .catch((err) => {
      return err;
    });

export const _CheckoutToOrder = () =>
  Axios.get<CheckoutToOrderData>(`/checkout/to-order`, {
    headers: {
      'x-channel': 'website',
    },
  }).then((res) => res.data);

export const _CheckoutToPayment = () => Axios.post<{ iframe: string }>(`/checkout/to-payment`).then((res) => res.data);

export const _SelectSameDayDelivery = () =>
  Axios.get<CheckoutData>(`/checkout/same-day-delivery`).then((res) => res.data);

export const _DeSelectSameDayDelivery = () =>
  Axios.delete<CheckoutData>(`/checkout/same-day-delivery`).then((res) => res.data);

export const _GetPickupCitiesList = () =>
  Axios.get<APIResponse<PickupCity[]>>(`/lookups/pick-up-cities`).then((res) => res.data);

export const _GetPickupPoint = (
  params?: DefaultPagination & {
    district__id?: number;
  }
) =>
  Axios.get<APIResponse<PickupPoint[]>>(`/lookups/pick-up-points`, {
    params,
  }).then((res) => res.data);
