import { COOKIE_AUTH, COOKIE_AUTH_REFRESH, COOKIE_GUEST, COOKIE_GUEST_REFRESH } from '@Constants/index';
import { createModel } from '@rematch/core';
import { _GetUserProfile, _GuestRegister, _VerifyRefreshToken, _VerifyToken } from '@Services/Authentication';
import Cookies from 'js-cookie';
import { configureAxiosAuth } from 'lib/Axios';
import { UserProfile } from 'types/User';
import { RootModel } from '.';

type AuthState = {
  userData: null | UserProfile;
  isUserFetching: boolean;
  // setUserData,
  // setLoginModal,
  // updateToken,
  // logOut,
  // updateCookieRedirect,
  loginModal: boolean;
  isTokenValid: boolean;
};

const auth = createModel<RootModel>()({
  name: 'auth',
  state: {
    userData: null,
    isTokenValid: false,
    loginModal: false,
    isUserFetching: true,
  } as AuthState,
  reducers: {
    setUserData(state, payload: UserProfile | null) {
      state.userData = payload;
    },
    setIsUserFetching(state, payload: boolean) {
      state.isUserFetching = payload;
    },
    setLoginModal(state, payload: boolean) {
      state.loginModal = payload;
    },
    setIsTokenValid(state, payload: boolean) {
      state.isTokenValid = payload;
    },
    _updateToken(
      state,
      {
        token,
        refresh,
        isUserToken,
      }: {
        token: string;
        refresh: string;
        isUserToken?: boolean;
      }
    ) {
      if (isUserToken) {
        Cookies.set(COOKIE_AUTH, token, {
          expires: 30 * 12,
        });
        Cookies.set(COOKIE_AUTH_REFRESH, refresh, {
          expires: 30 * 12,
        });
      } else {
        Cookies.set(COOKIE_GUEST, token, {
          expires: 30 * 12,
        });
        Cookies.set(COOKIE_GUEST_REFRESH, token, {
          expires: 30 * 12,
        });
      }
      configureAxiosAuth(token);
    },
  },
  effects: (dispatch) => ({
    async _fetchUserData() {
      const cookieUserToken = Cookies.get(COOKIE_AUTH);
      dispatch.auth.setIsUserFetching(true);
      let isUserExist = false;
      await _GetUserProfile(cookieUserToken as string)
        .then(({ data }) => {
          dispatch.auth.setUserData(data);
          Cookies.remove(COOKIE_GUEST);
          Cookies.remove(COOKIE_GUEST_REFRESH);
          isUserExist = true;
        })
        .catch((err) => {
          dispatch.auth.setUserData(null);
          Cookies.remove(COOKIE_AUTH);
          Cookies.remove(COOKIE_AUTH_REFRESH);
          configureAxiosAuth();
          _GuestRegister().then(({ data }) => {
            dispatch.auth._updateToken({
              token: data.access,
              refresh: data.refresh,
              isUserToken: false,
            });
          });
        })
        .finally(() => {
          // if (!ACL_Action) {
          //   setIsUserFetching(false);
          // } else {
          //   if (ACL_Action === RIA && isUserExist) {
          //     replace('/').then(() => setIsUserFetching(false));
          //   } else if (ACL_Action === RINA && !isUserExist) {
          //     replace('/auth/login').then(() => setIsUserFetching(false));
          //   } else {
          //     setIsUserFetching(false);
          //   }
          // }
        });
    },
    async _verifyToken({ token, refreshToken }) {
      const cookieUserRefreshToken = Cookies.get(COOKIE_AUTH_REFRESH);
      try {
        return await _VerifyToken(token).then(() => {
          configureAxiosAuth(token);
          dispatch.auth.setIsTokenValid(true);
        });
      } catch {
        _VerifyRefreshToken(refreshToken).then(({ data }) => {
          configureAxiosAuth(data.access);
          dispatch.auth.setIsTokenValid(true);
          if (refreshToken === cookieUserRefreshToken) {
            dispatch.auth._updateToken({
              token: data.access,
              refresh: data.refresh,
              isUserToken: true,
            });
            dispatch.auth._fetchUserData();
          } else {
            dispatch.auth._updateToken({
              token: data.access,
              refresh: data.refresh,
              isUserToken: false,
            });
          }
        });
      }
    },
    async Auth() {
      const cookieUserToken = Cookies.get(COOKIE_AUTH);
      const cookieUserRefreshToken = Cookies.get(COOKIE_AUTH_REFRESH);
      const cookieGuestToken = Cookies.get(COOKIE_GUEST);
      const cookieGuestRefreshToken = Cookies.get(COOKIE_GUEST_REFRESH);
      if (cookieUserToken && cookieUserRefreshToken) {
        dispatch.auth.setIsUserFetching(true);
        dispatch.auth
          ._verifyToken({
            token: cookieUserToken,
            refreshToken: cookieUserRefreshToken,
          })
          .then(() => {
            dispatch.auth._fetchUserData();
          });
      } else if (cookieGuestToken && cookieGuestRefreshToken) {
        dispatch.auth
          ._verifyToken({
            token: cookieGuestToken,
            refreshToken: cookieGuestRefreshToken,
          })
          .then(() => {
            dispatch.auth.setIsUserFetching(false);
          });
      } else {
        _GuestRegister().then(({ data }) => {
          dispatch.auth._updateToken({
            token: data.access,
            refresh: data.refresh,
            isUserToken: false,
          });
          dispatch.auth.setIsUserFetching(false);
        });
      }
    },
  }),
});

export default auth;
