import { FACEBOOK_PIXEL_ID, PROJECT_ENV } from 'app.config';
import Script from 'next/script';
import { Fragment } from 'react';

export const pageview = () => {
  if (typeof window !== 'undefined' && window.fbq) {
    window.fbq('track', 'PageView');
  }
};

// https://developers.facebook.com/docs/facebook-pixel/advanced/
export const event = (name: string, options = {}) => {
  if (typeof window !== 'undefined' && window.fbq) {
    window.fbq('track', name, options);
  }
};

export const FacebookPixelScript = () =>
  PROJECT_ENV ? (
    <Fragment>
      <Script
        strategy="afterInteractive"
        id="FB_PIXEL"
        dangerouslySetInnerHTML={{
          __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', ${FACEBOOK_PIXEL_ID});
          `,
        }}
      />
      <noscript
        dangerouslySetInnerHTML={{
          __html: `
            <img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=${FACEBOOK_PIXEL_ID}&ev=PageView&noscript=1" />
          `,
        }}
      />
    </Fragment>
  ) : (
    <Fragment></Fragment>
  );
