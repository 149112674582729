// Global Config
export const SITE_TITLE = 'Yourparts';
export const SITE_SOCIAL_USERNAME = 'yourparts';
export const SITE_DESCRIPTION = 'Your Parts Description';
export const SITE_URL = process.env.NEXT_PUBLIC_SITE_URL || 'https://yourparts.com';
export const API_URL = process.env.NEXT_PUBLIC_URL_API;
export const SITE_LOCALES = ['en', 'ar'];
export const SITE_DEFAULT_LOCALE = 'en';
export const THEME_COLOR = '#FFF';
export const LOGO_URL = SITE_URL + '/logo.png';
export const PROJECT_ENV =
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement &&
  process.env.NODE_ENV !== 'development';
export const FACEBOOK_PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID;
export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GOOGLE_TAG;
export const X_SECRET =
  (process.env.NEXT_PUBLIC_X_SECRET as string) || '07f3f114bb56801cbf289c3a16411602b39be97d9d41f9f70904f9dca653895b';
// ! Uncovered Files: next.config.js, sentry.server.js, sentry.client.js
