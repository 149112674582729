import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';
import { mq } from './utils';

export default createGlobalStyle<{ readonly locale?: string }>`
  ${normalize}
  *,
  *:before,
  *:after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  html {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
    font-size: ${({ theme }) => theme.typography.htmlBaseFontPx}px;
    ${mq(768)} {
      font-size: 8px;
    }
  }

  body {
    font-size: 1.6rem;
    line-height: ${({ theme }) => theme.typography.baseLineHeight};
    position: relative;
    cursor: default;
    font-weight: normal;
    font-family: ${({ theme, locale }) =>
      locale === 'ar' ? theme.typography.fontFamilyAr : theme.typography.fontFamily};
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.black};
  }

  @media print {
    html, body {
      width: 210mm; 
      height: 280mm;
      -webkit-print-color-adjust: exact;
    }
  }

  [hidden] {
    display: none;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
  }

  a,
  a:hover,
  a:visited,
  a:link,
  a:active {
    text-decoration: none;
    outline: none;
  }

  button,
  button:focus {
    outline: none;
  }

  button {
    appearance: none;
    cursor: pointer;
    padding: 0;
    outline: none;
    border: none;
    background: transparent;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  input:focus,
  select:focus,
  textarea:focus {
    outline: none;
  }

  .en {
    font-family: ${({ theme }) => theme.typography.fontFamily};
  }

  .ar {
    font-family: ${({ theme }) => theme.typography.fontFamilyAr};
  }
  
  .ReactCollapse--collapse {
    transition: height 300ms;
  }
  .contentIsVaild{
    border: 1px solid #6EB527!important;
  }
  .contentIsVaild button{
    background-color: #6EB527;
  }
  .contentUnVaild{
    border: 1px solid #EE3E55!important;
  }
  .contentUnVaild button{
    background-color: #EE3E55;
  }
  .textCoupon{
    font-size: 14px;
    margin-bottom: 10px;
    margin-top: -4px;
    color: #EE3E55;
  }
`;
