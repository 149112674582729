import styled from 'styled-components';
import { rotateSpinner } from 'stylesheets/animations';

export const Spinner = styled.div<{
  readonly size?: number;
}>`
  min-height: ${({ size }) => (size ? size : '30')}px;
  min-width: ${({ size }) => (size ? size : '30')}px;
  max-height: ${({ size }) => (size ? size : '30')}px;
  max-width: ${({ size }) => (size ? size : '30')}px;
  color: #333;
  display: inline-block;
  border: 2px solid #ddd;
  border-radius: 50%;
  border-right-color: #333;
  animation: ${rotateSpinner} 1s linear infinite;
`;

export const CenteredSpinnerWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
