import { DefaultOptions, MutationCache, QueryCache } from 'react-query';

const ReactQueryConfig: {
  queryCache?: QueryCache;
  mutationCache?: MutationCache;
  defaultOptions?: DefaultOptions;
} = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 2,
      // ! uncomment the following if you want to Fetch a query only once until page refresh using React Query
      // retry: false,
      // refetchOnMount: false,
      // refetchOnReconnect: false,
      // staleTime: 24 * 60 * 60 * 1000,
    },
  },
};

export default ReactQueryConfig;

// Using with Next.js
// https://react-query.tanstack.com/guides/ssr#using-nextjs
