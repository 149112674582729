import { createModel } from '@rematch/core';
import { _GetCarsSpecs } from '@Services/Car';
import { CarSpecs } from 'types/Car';
import { RootModel } from '.';

type CarSpecsState = {
  data: null | CarSpecs[];
  isLoading: boolean;
};

const carSpecs = createModel<RootModel>()({
  name: 'carSpecs',
  state: {
    data: null,
    isLoading: false,
  } as CarSpecsState,
  reducers: {
    setCarSpecs(state, payload: CarSpecs[]) {
      state.data = payload;
    },
    setLoading(state, payload: boolean) {
      state.isLoading = payload;
    },
  },
  effects: (dispatch) => ({
    async getCarSpecs() {
      dispatch.carSpecs.setLoading(true);
      const data = await _GetCarsSpecs();
      dispatch.carSpecs.setCarSpecs(data.results);
      dispatch.carSpecs.setLoading(false);
    },
  }),
});

export default carSpecs;
