import { API_URL } from 'app.config';
import theAxios from 'axios';

const Axios = theAxios.create({
  baseURL: API_URL,
});

// Add trailing slash
Axios.interceptors.request.use((config) => {
  if (config.url?.[config.url.length - 1] !== '/') {
    config.url += '/';
  }
  return config;
});
export const configureAxiosLocale = (locale: string = 'en') => {
  Axios.defaults.headers.common['Content-Language'] = locale;
  Axios.defaults.headers.common['Accept-Language'] = locale;
};

export const configureAxiosAuth = (token?: string) => {
  Axios.defaults.headers.common['Authorization'] = token ? 'Bearer ' + token : '';
};

export default Axios;
