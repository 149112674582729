import { createModel } from '@rematch/core';
import { _GetCategories } from '@Services/Categories';
import { PartCategory } from 'types/Categories';
import { RootModel } from '.';

type CategoriesState = {
  data: null | PartCategory[];
  isLoading: boolean;
};

const categories = createModel<RootModel>()({
  name: 'categories',
  state: {
    data: null,
    isLoading: false,
  } as CategoriesState,
  reducers: {
    setCategories(state, payload: PartCategory[]) {
      state.data = payload;
    },
    setLoading(state, payload: boolean) {
      state.isLoading = payload;
    },
  },
  effects: (dispatch) => ({
    async getCategories() {
      dispatch.categories.setLoading(true);
      const data = await _GetCategories({
        parent__isnull: true,
      });
      dispatch.categories.setCategories(data.results);
      dispatch.categories.setLoading(false);
    },
  }),
});

export default categories;
