import Axios from 'lib/Axios';
import { APIResponse } from 'types/APIResponse';
import { DefaultPagination } from 'types/common';
import { Product } from 'types/Products';

export const _GetProfileWishlist = (params?: DefaultPagination) =>
  Axios.get<APIResponse<Product[]>>('/profile/wishlist', {
    params,
  }).then((res) => res.data);

export const _AddToWishlist = (product_id: number) =>
  Axios.post<{
    items: Product[];
  }>(`/profile/wishlist/${product_id}`).then((res) => res.data);

export const _DeleteFromWishlist = (product_id: number) =>
  Axios.delete<{
    items: Product[];
  }>(`/profile/wishlist/${product_id}`).then((res) => res.data);
