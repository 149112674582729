import { createModel } from '@rematch/core';
import { _GetCheckout, _GetPaymentMethods } from '@Services/Checkout';
import { CheckoutData, PaymentMethods } from 'types/Checkout';
import { RootModel } from '.';

type CheckoutState = {
  data: null | CheckoutData;
  paymentMethods: null | PaymentMethods;
  isLoading: boolean;
  firstIsLoading: boolean;
};

const checkout = createModel<RootModel>()({
  name: 'checkout',
  state: {
    data: null,
    isLoading: false,
    paymentMethods: null,
    firstIsLoading: true,
  } as CheckoutState,
  reducers: {
    setCheckoutData(state, payload: CheckoutData) {
      state.data = payload;
    },
    setPaymentMethods(state, payload: PaymentMethods) {
      const data = payload;
      if (data.other_payment) {
        // @ts-ignore
        delete data.other_payment;
      }
      state.paymentMethods = payload;
    },
    setLoading(state, payload: boolean) {
      state.isLoading = payload;
    },
    setFirstIsLoading(state, payload: boolean) {
      state.firstIsLoading = payload;
    },
  },
  effects: (dispatch) => ({
    async getCheckoutData() {
      const data = await _GetCheckout();
      const paymentMethodsData = await _GetPaymentMethods();
      dispatch.checkout.setCheckoutData(data);
      dispatch.checkout.setPaymentMethods(paymentMethodsData.results);
      dispatch.checkout.setFirstIsLoading(false);
    },
  }),
});

export default checkout;
