export const GridTheme = {
  gridColumns: 12, // default 12
  breakpoints: {
    // defaults below
    xl: 1440,
    lg: 1200,
    md: 992,
    sm: 768,
    xs: 576,
    // or you can use aliases
    // veryGiant: 1440,
    // giant: 1200,
    // desktop: 992,
    // tablet: 768,
    // phone: 576,
    // smaller: 575,
  },
  row: {
    padding: 10, // default 15
  },
  col: {
    padding: 10, // default 15
  },
  container: {
    padding: 10, // default 15
    maxWidth: {
      // defaults below
      xl: 1290,
      lg: 1160,
      md: 980,
      sm: 740,
      xs: 560,
      // or you can use aliases
      // veryGiant: 1141,
      // giant: 1140,
      // desktop: 960,
      // tablet: 720,
      // phone: 540,
      // smaller: 540,
    },
  },
};
