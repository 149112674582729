import Axios from 'lib/Axios';
import { AddToCartParams, UserCart } from 'types/Cart';
import { DefaultPagination } from 'types/common';

export const _GetCartList = (params?: DefaultPagination) =>
  Axios.get<UserCart>('/shopcart', { params }).then((res) => res.data);

export const _AddProductToCart = (data?: AddToCartParams) =>
  Axios.post<UserCart>('/shopcart', data).then((res) => res.data);

export const _UpdateProductInCart = ({ product_id, ...data }: AddToCartParams) =>
  Axios.patch<UserCart>(`/shopcart/item/${product_id}`, data).then((res) => res.data);

export const _DeleteProductFromCart = (product_id: number) =>
  Axios.delete<UserCart>(`/shopcart/${product_id}`).then((res) => res.data);
