import { Models } from '@rematch/core';
import auth from './Auth.model';
import carSpecs from './CarSpecs.model';
import cart from './Cart.model';
import categories from './Categories.model';
import checkout from './Checkout.model';
import profileCars from './ProfileCars.model';
import profileWishlist from './ProfileWishlist.model';

export interface RootModel extends Models<RootModel> {
  auth: typeof auth;
  categories: typeof categories;
  carSpecs: typeof carSpecs;
  profileCars: typeof profileCars;
  profileWishlist: typeof profileWishlist;
  cart: typeof cart;
  checkout: typeof checkout;
}

export const models: RootModel = { categories, cart, carSpecs, profileCars, profileWishlist, checkout, auth };
