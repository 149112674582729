import { DefaultTheme } from 'styled-components';

declare module 'styled-components' {
  export interface DefaultTheme {
    typography: {
      fontFamily: string;
      fontFamilyAr: string;
      htmlBaseFontPx: number;
      baseLineHeight: number;
    };
    // Colors
    colors: {
      lightenBlue: string;
      lightPurple: string;
      lightenPurple: string;
      lightLavender: string;
      lightenGray: string;
      heroOverlay: string;
      filterCardBg: string;
      cartBg: string;
      secondaryBackground: string;
      arrowBackground: string;
      yellow: string;
      green: string;
      checkCheck: string;
      darkYellow: string;
      blue: string;
      babyBlue: string;
      darkGray: string;
      counter: string;
      sidebar: string;
      aboutOverlay: string;
      innerProduct: {
        lightGray: string;
        gray: string;
        progress: string;
      };
      pagination: {
        gray: string;
        lightGray: string;
        lightenGray: string;
        babyBlue: string;
        black: string;
      };
      auth: {
        blue: string;
        overlay: string;
        lightPurple: string;
      };
      gray: string;
      black: string;
      black2: string;
      white: string;
      brandColor: string;
      brandColorHover: string;
      brandColorActive: string;
      darkBrandColor: string;
      lightBlue: string;
      darkBlue: string;
      cartCountColor: string;
      lightGray: string;
      disabled: string;
      menuBorder: string;
      social: {
        google: string;
        twitter: string;
        apple: string;
        appleText: string;
        phone: string;
      };
      status: {
        error: string;
        success: string;
        warning: string;
      };
    };
    fontsizes: {
      h1: string;
      h2: string;
      h3: string;
      h4: string;
      h5: string;
      h6: string;
      body18: string;
      body16: string;
      body14: string;
      body12: string;
    };
    fontWeight: {
      font300: number;
      font400: number;
      font500: number;
      font600: number;
      font700: number;
      font800: number;
    };
  }
}

const theme: DefaultTheme = {
  // Typography
  typography: {
    fontFamily: 'Poppins, sans-serif',
    fontFamilyAr: 'Almarai, sans-serif',
    htmlBaseFontPx: 10,
    baseLineHeight: 1.5,
  },
  // Colors
  colors: {
    lightenBlue: '#DBE1E6',
    lightPurple: '#8086A4',
    lightenPurple: '#9499B9',
    lightenGray: '#F3F3F3',
    lightLavender: '#F3F5FF',
    heroOverlay: '#0B284D',
    filterCardBg: '#F8F9FB',
    cartBg: '#F6F7FF',
    secondaryBackground: '#F6FAFD',
    arrowBackground: '#E4E7FB',
    yellow: '#FFE469',
    green: '#6EB527',
    checkCheck: '#59B748',
    darkYellow: '#FFD739',
    babyBlue: '#CBF6FF',
    darkGray: '#6A6F8A',
    blue: '#0075DC',
    counter: '#DDE0F0',
    sidebar: '#6C7DBB',
    aboutOverlay: '#09165D',
    innerProduct: {
      lightGray: '#E4E4E4',
      gray: '#EEEFF5',
      progress: '#66D2FF',
    },
    pagination: {
      gray: '#828282',
      lightGray: '#eee',
      lightenGray: '#fafafa',
      babyBlue: '#E5F5FF',
      black: '#4F4F4F',
    },
    auth: {
      blue: '#0098FE',
      overlay: '#707070',
      lightPurple: '#C4C8E3',
    },
    gray: '#545870',
    black: '#0C0C0C',
    black2: '#24231F',
    white: '#fff',
    brandColor: '#EE3E55',
    brandColorHover: '#f24b61',
    brandColorActive: '#db354a',
    darkBrandColor: '#DC1421',
    lightBlue: '#eeeff5',
    darkBlue: '#2F3140',
    cartCountColor: '#0097FF',
    lightGray: '#CCCCCC',
    disabled: '#ababab',
    menuBorder: '#C4C8E3',
    social: {
      google: '#EA4335',
      twitter: '#1da1f2',
      apple: '#1A1A1A',
      appleText: '#fff',
      phone: '#13AF72',
    },
    status: {
      error: '#dc3545',
      success: '#28a745',
      warning: '#ffc107',
    },
  },
  fontsizes: {
    h1: '9.6rem',
    h2: '6rem',
    h3: '4.8rem',
    h4: '3.4rem',
    h5: '2.4rem',
    h6: '2rem',
    body18: '1.8rem',
    body16: '1.6rem',
    body14: '1.4rem',
    body12: '1.2rem',
  },
  fontWeight: {
    font300: 300,
    font400: 400,
    font500: 500,
    font600: 600,
    font700: 700,
    font800: 800,
  },
};

export default theme;
