import { createModel } from '@rematch/core';
import { _AddToWishlist, _DeleteFromWishlist, _GetProfileWishlist } from '@Services/WishList';
import { AxiosError } from 'axios';
import Axios from 'lib/Axios';
import getT from 'next-translate/getT';
import { toast } from 'react-toastify';
import { Product } from 'types/Products';
import { RootModel } from '.';

type ProfileWishlistState = {
  data: null | Product[];
  isLoading: boolean;
};

const ProfileWishlist = createModel<RootModel>()({
  name: 'profileWishlist',
  state: {
    data: null,
    isLoading: false,
  } as ProfileWishlistState,
  reducers: {
    setProfileWishlist(state, payload: Product[]) {
      state.data = payload;
    },
    setLoading(state, payload: boolean) {
      state.isLoading = payload;
    },
  },
  effects: (dispatch) => ({
    async getProfileWishlist() {
      dispatch.profileWishlist.setLoading(true);
      const data = await _GetProfileWishlist();
      dispatch.profileWishlist.setProfileWishlist(data.results);
      dispatch.profileWishlist.setLoading(false);
    },
    async addProductToWishlist(product_id: number) {
      dispatch.profileWishlist.setLoading(true);
      const locale = Axios.defaults.headers.common['Accept-Language'] as string;
      const t = await getT(locale, 'common');
      try {
        const data = await _AddToWishlist(product_id);
        dispatch.profileWishlist.setProfileWishlist(data.items);
        dispatch.profileWishlist.setLoading(false);
        toast(t('added-to-wishlist'), {
          type: 'success',
        });
      } catch (error) {
        const err = error as AxiosError;
        toast(err.response?.data.message || t('something-went-wrong'), {
          type: 'success',
        });
      }
    },
    async deleteProductFromWishlist(product_id: number) {
      dispatch.profileWishlist.setLoading(true);
      const locale = Axios.defaults.headers.common['Accept-Language'] as string;
      const t = await getT(locale, 'common');
      try {
        const data = await _DeleteFromWishlist(product_id);
        dispatch.profileWishlist.setProfileWishlist(data.items);
        dispatch.profileWishlist.setLoading(false);
        toast(t('removed-from-wishlist'), {
          type: 'success',
        });
      } catch (error) {
        const err = error as AxiosError;
        toast(err.response?.data.message || t('something-went-wrong'), {
          type: 'success',
        });
      }
    },
  }),
});

export default ProfileWishlist;
