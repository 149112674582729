import { CookieSerializeOptions } from 'cookie';

export const DEFAULT_LOCALE = 'en';
export const COOKIE_LANG = 'NEXT_LOCALE';
export const LOGIN_REDIRECT = 'LOGIN_REDIRECT';

export const COOKIE_AUTH = 'YOURPARTS_TOKEN';
export const COOKIE_AUTH_REFRESH = 'YOURPARTS_REFRESH_TOKEN';
export const COOKIE_GUEST = 'YOURPARTS_GUEST_TOKEN';
export const COOKIE_GUEST_REFRESH = 'YOURPARTS__GUEST_REFRESH_TOKEN';

export const LOCAL_STORAGE_REFERRER = 'YOURPARTS_REFERRER_URL';

export const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
export const phonePattern = /^\d{0,13}$/i;
export const websitePattern =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/i;
/**
 * Instagram username Pattern
 * - Minimum 3 Chars, Maximum 30 Chars
 * - Accepts only _ and .  not squentially
 * - can't start or end with ., but can start or end with _
 */
export const usernamePattern = /^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{3,29}$/i;
/**
 * Default Cookie Settings
 */
export const DEFAULT_COOKIE_SETTINGS: CookieSerializeOptions = {
  maxAge: 30 * 24 * 60 * 60,
  path: '/',
};
