import Axios from 'lib/Axios';
import { APIResponse } from 'types/APIResponse';
import { Car, CarParameters, CarSpecs, ProfileCar } from 'types/Car';
import { DefaultPagination } from 'types/common';

export const _GetCarsSpecs = (params?: DefaultPagination) =>
  Axios.get<APIResponse<CarSpecs[]>>('/lookups/car-specs', { params }).then((res) => res.data);

export const _GetCarsList = (params?: CarParameters) =>
  Axios.get<APIResponse<Car[]>>('/lookups/cars', { params }).then((res) => res.data);

export const _SaveCarToProfile = (carId?: number) =>
  Axios.get<APIResponse<ProfileCar[]>>(`/profile/my-cars/${carId}`).then((res) => res.data);

export const _SetCarAsFavorite = (carId?: number) =>
  Axios.get<APIResponse<ProfileCar[]>>(`/profile/my-cars/favorite/${carId}`).then((res) => res.data);

export const _GetProfileCars = () => Axios.get<APIResponse<ProfileCar[]>>(`/profile/my-cars`).then((res) => res.data);

export const _DeleteCarFromProfile = (carId: number) => Axios.delete(`/profile/my-cars/${carId}`);
