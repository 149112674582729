import { createModel } from '@rematch/core';
import { _AddProductToCart, _DeleteProductFromCart, _GetCartList, _UpdateProductInCart } from '@Services/Cart';
import { AxiosError } from 'axios';
import Axios from 'lib/Axios';
import getT from 'next-translate/getT';
import { toast } from 'react-toastify';
import { AddToCartParams, UserCart } from 'types/Cart';
import { RootModel } from '.';

type CartState = {
  data: null | UserCart;
  isLoading: boolean;
  firstIsLoading: boolean;
};

const cart = createModel<RootModel>()({
  name: 'cart',
  state: {
    data: null,
    isLoading: false,
    firstIsLoading: true,
  } as CartState,
  reducers: {
    setCartData(state, payload: UserCart) {
      state.data = payload;
    },
    setLoading(state, payload: boolean) {
      state.isLoading = payload;
    },
    setFirstIsLoading(state, payload: boolean) {
      state.firstIsLoading = payload;
    },
  },
  effects: (dispatch) => ({
    async getCartList() {
      const data = await _GetCartList();
      dispatch.cart.setCartData(data);
      dispatch.cart.setLoading(false);
      dispatch.cart.setFirstIsLoading(false);
    },
    async addProductToCart(params: AddToCartParams) {
      dispatch.cart.setLoading(true);
      const locale = Axios.defaults.headers.common['Accept-Language'] as string;
      const t = await getT(locale, 'common');
      try {
        const data = await _AddProductToCart(params);
        dispatch.cart.setCartData(data);
        dispatch.cart.setLoading(false);
        toast(t('added-to-cart'), {
          type: 'success',
        });
      } catch (error) {
        const err = error as AxiosError;
        toast(err.response?.data.message || t('something-went-wrong'), {
          type: 'error',
        });
      }
    },
    async updateProductInCart(params: AddToCartParams) {
      dispatch.cart.setLoading(true);
      const data = await _UpdateProductInCart(params);
      dispatch.cart.setCartData(data);
      dispatch.cart.setLoading(false);
    },
    async deleteProductFromCart(product_id: number) {
      dispatch.cart.setLoading(true);
      const locale = Axios.defaults.headers.common['Accept-Language'] as string;
      const t = await getT(locale, 'common');
      try {
        const data = await _DeleteProductFromCart(product_id);
        dispatch.cart.setCartData(data);
        dispatch.cart.setLoading(false);
        toast(t('removed-from-cart'), {
          type: 'success',
        });
      } catch (error) {
        const err = error as AxiosError;
        toast(err.response?.data.message || t('something-went-wrong'), {
          type: 'error',
        });
      }
    },
  }),
});

export default cart;
