import { Spinner } from '@Components/common/Spinner';
import { RIA, RINA } from '@Constants/ACL';
import { COOKIE_AUTH, COOKIE_AUTH_REFRESH, COOKIE_GUEST, COOKIE_GUEST_REFRESH, LOGIN_REDIRECT } from '@Constants/index';
import { _GetUserProfile, _GuestRegister, _VerifyRefreshToken, _VerifyToken } from '@Services/Authentication';
import dayjs from 'dayjs';
import 'dayjs/locale/ar';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import { configureAxiosAuth, configureAxiosLocale } from 'lib/Axios';
import { NextSeo } from 'next-seo';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { useCookie } from 'react-use';
import { IAuthContext, UserProfile } from 'types/User';

dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);

interface Props {
  ACL?: {
    action: typeof RIA | typeof RINA;
  };
}

const initialState: IAuthContext = {
  isUserFetching: false,
  setUserData: () => null,
  logOut: () => null,
  // updateToken: () => null,
  loginModal: false,
  isTokenValid: false,
  setLoginModal: () => null,
  updateCookieRedirect: () => null,
  updateToken: () => null,
  setIsTokenValid: () => null,
};

const UserContext = React.createContext<IAuthContext>(initialState);
UserContext.displayName = 'Auth Context'; // Only for debugging

export const useAuth = () => useContext(UserContext);
export const AuthProvider: React.FC<Props> = ({ children, ACL }) => {
  const isClientSide = typeof window !== 'undefined' && window.document && window.document.createElement;
  const ACL_Action = ACL?.action;
  const { lang } = useTranslation();
  // Auth Cookies : Start
  const [cookieUserToken, setCookieUserToken, deleteCookieUserToken] = useCookie(COOKIE_AUTH);
  const [cookieUserRefreshToken, setCookieUserRefreshToken, deleteCookieUserRefreshToken] =
    useCookie(COOKIE_AUTH_REFRESH);

  const [cookieGuestToken, setCookieGuestToken, deleteCookieGuestToken] = useCookie(COOKIE_GUEST);
  const [cookieGuestRefreshToken, setCookieGuestRefreshToken, deleteCookieGuestRefreshToken] =
    useCookie(COOKIE_GUEST_REFRESH);
  // Auth Cookies : End

  const [cookieRedirect, updateCookieRedirect, deleteCookieRedirect] = useCookie(LOGIN_REDIRECT);
  const [userData, setUserData] = useState<UserProfile | undefined | null>(
    ACL_Action === 'REDIRECT_IF_NOT_AUTHORIZED' ? undefined : null
  );
  const [isUserFetching, setIsUserFetching] = useState<boolean>(Boolean(ACL_Action) && Boolean(cookieUserToken));
  const { replace } = useRouter();
  const [loginModal, setLoginModal] = useState(false);
  const [isTokenValid, setIsTokenValid] = useState(false);

  const updateToken = useCallback((token, refresh, isUserToken?: boolean) => {
    if (isUserToken) {
      setCookieUserToken(token, {
        expires: 30 * 12,
      });
      setCookieUserRefreshToken(refresh, {
        expires: 30 * 12,
      });
    } else {
      setCookieGuestToken(token, {
        expires: 30 * 12,
      });
      setCookieGuestRefreshToken(refresh, {
        expires: 30 * 12,
      });
    }
    configureAxiosAuth(token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchUserData = useCallback(() => {
    setIsUserFetching(true);
    let isUserExist = false;
    _GetUserProfile(cookieUserToken as string)
      .then(({ data }) => {
        setUserData(data);
        deleteCookieGuestToken();
        deleteCookieGuestRefreshToken();
        isUserExist = true;
      })
      .catch((err) => {
        setUserData(null);
        deleteCookieUserToken();
        configureAxiosAuth();
        _GuestRegister().then(({ data }) => {
          updateToken(data.access, data.refresh, false);
        });
      })
      .finally(() => {
        if (!ACL_Action) {
          setIsUserFetching(false);
        } else {
          if (ACL_Action === RIA && isUserExist) {
            replace('/').then(() => setIsUserFetching(false));
          } else if (ACL_Action === RINA && !isUserExist) {
            replace('/auth/login').then(() => setIsUserFetching(false));
          } else {
            setIsUserFetching(false);
          }
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logOut = useCallback(
    () => {
      setIsUserFetching(true);
      deleteCookieUserToken();
      deleteCookieUserRefreshToken();
      deleteCookieGuestToken();
      deleteCookieGuestRefreshToken();
      deleteCookieRedirect();
      _GuestRegister().then(({ data }) => {
        setUserData(null);
        updateToken(data.access, data.refresh, false);
        setIsUserFetching(false);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const verifyToken = useCallback(async (token, refreshToken) => {
    try {
      return await _VerifyToken(token).then(() => {
        configureAxiosAuth(token);
        setIsTokenValid(true);
      });
    } catch {
      _VerifyRefreshToken(refreshToken).then(({ data }) => {
        configureAxiosAuth(data.access);
        setIsTokenValid(true);
        if (refreshToken === cookieUserRefreshToken) {
          updateToken(data.access, data.refresh, true);
          fetchUserData();
        } else {
          updateToken(data.access, data.refresh, false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isClientSide) {
      if (cookieUserToken && cookieUserRefreshToken) {
        setIsUserFetching(true);
        verifyToken(cookieUserToken, cookieUserRefreshToken).then(() => {
          fetchUserData();
        });
      } else if (cookieGuestToken && cookieGuestRefreshToken) {
        verifyToken(cookieGuestToken, cookieGuestRefreshToken).then(() => {
          setIsUserFetching(false);
        });
      } else {
        _GuestRegister().then(({ data }) => {
          updateToken(data.access, data.refresh, false);
          setIsTokenValid(true);
          setIsUserFetching(false);
        });
      }
    }

    return () => {
      deleteCookieRedirect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // To Handle redirect after login
  useEffect(() => {
    if (isTokenValid) {
      if (Boolean(userData)) {
        if (cookieRedirect) {
          let redirect = JSON.parse(cookieRedirect);
          replace(redirect.pathname, redirect.asPath).then(() => deleteCookieRedirect());
        } else {
          if (ACL_Action === RIA) {
            replace('/').then(() => setIsUserFetching(false));
          } else {
            setIsUserFetching(false);
          }
        }
      } else {
        if (!cookieUserToken) {
          if (ACL_Action === RINA) replace('/auth/login').then(() => setUserData(null)); // ! Hna
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTokenValid, userData, cookieRedirect]);

  useEffect(() => {
    configureAxiosLocale(lang);
    dayjs.locale(lang);
  }, [lang]);

  if ((!isTokenValid && ACL_Action && cookieUserToken) || userData === undefined) {
    return (
      <Fragment>
        <NextSeo title={`Loading...`} />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
          }}
        >
          <Spinner />
        </div>
      </Fragment>
    );
  }

  return (
    <UserContext.Provider
      value={{
        userData,
        setUserData,
        isUserFetching,
        loginModal,
        setLoginModal,
        updateToken,
        logOut,
        updateCookieRedirect,
        isTokenValid,
        setIsTokenValid,
      }}
    >
      <div dir={lang === 'ar' ? 'rtl' : 'ltr'}>{children}</div>
    </UserContext.Provider>
  );
};
