import { useAuth } from '@Contexts/AuthContext/Auth.context';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { Provider, useDispatch } from 'react-redux';
import { Dispatch, store } from 'store';

type Props = {
  children: JSX.Element;
};

const ProviderInner = ({ children }: Props) => {
  const { isTokenValid, userData } = useAuth();
  const { locale } = useRouter();
  const dispatch = useDispatch<Dispatch>();

  useEffect(() => {
    if (isTokenValid) {
      dispatch.categories.getCategories();
      dispatch.carSpecs.getCarSpecs();
      dispatch.profileCars.getProfileCars();
      dispatch.cart.getCartList();
      if (Boolean(userData)) {
        dispatch.profileWishlist.getProfileWishlist();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale, isTokenValid]);

  return children;
};

export default function GlobalStateProvider({ children }: Props) {
  return (
    <Provider store={store}>
      <ProviderInner>{children}</ProviderInner>
    </Provider>
  );
}
