import { X_SECRET } from 'app.config';
import Axios from 'lib/Axios';
import { ChangePassword } from 'types/ChangePassword';
import {
  LoginFormValues,
  RegisterFormValues,
  ResetPasswordFormValues,
  TokensResponse,
  UpdateFormValues,
  UserProfile,
} from 'types/User';

export const _GetUserProfile = (token?: string) =>
  Axios.get<UserProfile>('/profile/me', {
    ...(token
      ? {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      : {}),
  });

export const _UpdateProfile = (data: UpdateFormValues) => Axios.patch<UserProfile>('/profile/me', data);

export const _VerifyToken = (token: string) =>
  Axios.post<unknown>('/auth/token/verify', {
    token,
  });

export const _VerifyRefreshToken = (refresh: string) =>
  Axios.post<TokensResponse>('/auth/token/refresh', {
    refresh,
  });

export const _GuestRegister = () =>
  Axios.post<TokensResponse>('/auth/guest-register', undefined, {
    headers: {
      'x-secret': X_SECRET,
    },
  });

export const _ChangePassword = (data?: ChangePassword) => Axios.post<TokensResponse>('/auth/change-password', data);

export const _Register = (data: RegisterFormValues) => Axios.patch<TokensResponse>('/auth/register', data);
export const _Login = (data: LoginFormValues) => Axios.post<TokensResponse>('/auth/login', data);
export const _ForgotPassword = (data: { email: string }) =>
  Axios.post<{ message: string }>('/auth/forget-password', data);
export const _ResetPassword = (data: ResetPasswordFormValues) =>
  Axios.post<{ message: string }>('/auth/reset-password', data);
