import Axios from 'lib/Axios';
import { APIResponse } from 'types/APIResponse';
import { CategoryParameters, PartCategory } from 'types/Categories';

export const _GetCategories = (params?: CategoryParameters) =>
  Axios.get<APIResponse<PartCategory[]>>('/categories', {
    params,
  }).then((res) => res.data);

export const _GetCategoryDetails = (catId: string) =>
  Axios.get<PartCategory>(`/categories/${catId}`).then((res) => res.data);
