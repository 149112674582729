import {
  LOGO_URL,
  SITE_DEFAULT_LOCALE,
  SITE_DESCRIPTION,
  SITE_LOCALES,
  SITE_SOCIAL_USERNAME,
  SITE_TITLE,
  SITE_URL,
} from 'app.config';
import { DefaultSeoProps } from 'next-seo';
import { PageSEOParams, ProductPageSEOParams } from 'types/common';

const defaultSeo: DefaultSeoProps = {
  titleTemplate: `%s | ${SITE_TITLE}`,
  description: SITE_DESCRIPTION,
  languageAlternates: SITE_LOCALES.map((locale) => ({
    hrefLang: locale,
    href: locale === SITE_DEFAULT_LOCALE ? SITE_URL : SITE_URL + '/' + locale,
  })),
  openGraph: {
    title: SITE_TITLE,
    description: SITE_DESCRIPTION,
    type: 'website',
    locale: 'en_us',
    url: SITE_URL,
    site_name: SITE_TITLE,
    images: [
      {
        url: LOGO_URL,
        alt: SITE_TITLE,
      },
    ],
  },
  twitter: {
    handle: '@' + SITE_SOCIAL_USERNAME,
    site: '@' + SITE_SOCIAL_USERNAME,
    cardType: 'summary_large_image',
  },
};

export const generatePageSeo = ({ title, titleTemplate, asPath, description, lang }: PageSEOParams) => ({
  ...(titleTemplate ? { titleTemplate } : {}),
  title,
  ...(description ? { description } : {}),
  languageAlternates: SITE_LOCALES.map((locale) => ({
    hrefLang: lang,
    href: lang === SITE_DEFAULT_LOCALE ? SITE_URL + asPath : SITE_URL + '/' + locale + asPath,
  })),
  openGraph: {
    ...(description ? { description } : {}),
    type: 'website',
    locale: lang,
    url: SITE_URL + asPath,
    site_name: SITE_SOCIAL_USERNAME,
    images: [
      {
        url: LOGO_URL,
        alt: SITE_TITLE,
      },
    ],
  },
  twitter: {
    handle: '@' + SITE_SOCIAL_USERNAME,
    site: '@' + SITE_SOCIAL_USERNAME,
    cardType: 'summary_large_image',
  },
});

export const generateProductSeo = ({ product, asPath, lang, titleTemplate }: ProductPageSEOParams) => ({
  titleTemplate,
  title: product.name,
  languageAlternates: SITE_LOCALES.map((locale) => ({
    hrefLang: lang,
    href: lang === SITE_DEFAULT_LOCALE ? SITE_URL + asPath : SITE_URL + '/' + locale + asPath,
  })),
  canonical: SITE_URL + asPath,
  description: product.description,
  openGraph: {
    type: 'product',
    title: product.name,
    locale: lang,
    description: product.description,
    url: SITE_URL + asPath,
    images: [
      ...product.images.map((url) => ({
        url: url.url,
        width: 800,
        height: 600,
        alt: product.name,
      })),
    ],
  },
});

export default defaultSeo;
