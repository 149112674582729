import rtlcss from 'rtlcss';
import { StylisPlugin } from 'styled-components';

const STYLIS_CONTEXTS = {
  POST_PROCESS: -2,
  PREPARATION: -1,
  NEWLINE: 0,
  PROPERTY: 1,
  SELECTOR_BLOCK: 2,
  AT_RULE: 3,
};

const RTLCSS_PLUGIN: StylisPlugin = (context, content) => {
  if (context === STYLIS_CONTEXTS.PREPARATION) {
    return rtlcss.process(content as any);
  }
};

export default RTLCSS_PLUGIN;
