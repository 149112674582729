import { init, RematchDispatch, RematchRootState } from '@rematch/core';
import immerPlugin from '@rematch/immer';
import selectPlugin from '@rematch/select';
import { models, RootModel } from './models';
import createCachedSelector from 're-reselect';

export const store = init<RootModel>({
  models,
  plugins: [
    selectPlugin({
      selectorCreator: createCachedSelector,
    }),
    immerPlugin(),
  ],
});
export type Store = typeof store;
export type Dispatch = RematchDispatch<RootModel>;
export type RootState = RematchRootState<RootModel>;
