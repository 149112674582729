const breakpoints = {
  576: 575.98,
  768: 767.98,
  992: 991.98,
  1200: 1199.98,
};

export const mq = (key: keyof typeof breakpoints) => {
  return `@media (max-width: ${breakpoints[key]}px)`;
};

const hexToRgb = (hex: string) => {
  // http://stackoverflow.com/a/5624139
  let shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });

  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

export const rgba = (hex: string, alpha: number) => {
  const color = hexToRgb(hex) as any;
  return `rgba(${color.r}, ${color.g}, ${color.b}, ${alpha})`;
};
